import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import t from '../lib/translate';
import { useUrlParam } from '../lib/useUrlParam';

import Layout from '../layouts/default';

import Helmet from '../components/helmet';
import Breadcrumb from '../component-elements/breadcrumb';
import HeadlineHeader from '../component-elements/header/types/headline';
import ProductGrid from '../components/shop-category/product-grid';

const Search = ({
    location,
    pageContext: {
        lang,
        url
    },
    data: {
        allMagentoSearchterm: {
            nodes: searchRedirects = []
        }
    }
}) => {
    const searchTerm = useUrlParam('q');

    // search redirects
    useEffect(() => {
        const handleRedirect = () => {
            if (!searchTerm?.value) {
                return;
            }

            const searchTermMatch = searchRedirects
                .find(({ query }) => query.toLowerCase() === searchTerm.value.toLowerCase());

            if (!searchTermMatch) {
                return;
            }

            if (window.location.pathname !== searchTermMatch.redirect) {
                window.location.replace(searchTermMatch.redirect);
            }
        };

        // Verzögerung einbauen um Race Conditions zu vermeiden
        const timeoutId = setTimeout(handleRedirect, 300);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [searchTerm?.value, searchRedirects]);

    return (
        <Layout textcolorHeader="black" textcolorHeaderMobile="black">
            <Helmet metaRobots="noindex-nofollow" url={url}>
                <title>{t('searchresult_page_seo_title', searchTerm?.value || '')}</title>
            </Helmet>

            <Breadcrumb
                items={[
                    { id: 'searchresult', name: t`searchresult_page_name` }
                ]}
            />

            {/* loading search term */}
            {searchTerm == null && (
                <HeadlineHeader
                    headline={t`searchresult_page_name`}
                >
                    {t`searchresult_loading_text`}
                </HeadlineHeader>
            )}

            {searchTerm && (
                <>
                    {/* search term was loaded but is empty */}
                    {!searchTerm.value && (
                        <HeadlineHeader
                            headline={t('searchresult_page_name')}
                        >
                            {t('searchresult_empty_text')}
                        </HeadlineHeader>
                    )}

                    {/* search term was loaded and can be forwarded to nested components */}
                    {searchTerm.value && (
                        <>
                            <HeadlineHeader
                                headline={t('searchresult_header_text', searchTerm.value)}
                            />
                            <ProductGrid
                                lang={lang}
                                location={location}
                                searchTerm={searchTerm.value}
                                breadcrumb={[
                                    {
                                        id: 'search',
                                        name: t`searchresult_page_name`,
                                        url: `${process.env.GATSBY_SEARCH_PATH}?q=${searchTerm?.value}`
                                    }
                                ]}
                                isSearch
                            />
                        </>
                    )}
                </>
            )}
        </Layout>
    );
};

export const query = graphql`
  query search {
    allMagentoSearchterm(filter: {redirect: {ne: null}}) {
      nodes {
        query
        redirect
      }
    }
  }
`;

Search.propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    pageContext: PropTypes.shape({
        lang: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }).isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Search;
